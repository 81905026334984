<template>
  <div class="videoapp__meta-links">
    <div uk-grid class="uk-grid-small">
      <a
        v-for="link in links"
        :key="link.id"
        class=""
        :href="link.url"
        :target="'_' + link.target"
        >{{ link.text }}</a
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "MetaLinks",
  data: function() {
    return {
      links: this._vaData.strings.meta_links
    };
  },
  props: {}
};
</script>
<style lang="scss" scoped>
a {
  color: #000;
}
a:hover {
  color: #000;
}
.videoapp__meta-links {
  margin-top: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  font-size: 1em;
  opacity: 0.6;
}
.uk-grid-small > * {
  padding-left: 20px !important;
}
</style>
