<template>
  <div class="uk-position-relative slide">
    <div v-if="slide.title || slide.subtitle" class="header">
      <h3 class="slide__title" v-if="slide.title">{{ slide.title }}</h3>
      <span class="slide__subtitle" v-if="slide.subtitle">{{
        slide.subtitle
      }}</span>
    </div>
    <vue-custom-scrollbar
      :settings="scrollbar.settings"
      class="slide_media__body"
    >
      <div class="slide__media">
        <div v-if="!slide.src" class="media">
          <!-- Fallback -->
          <img src="fallback.jpg" alt="Asset not found" />
        </div>

        <template v-else>
          <div v-if="slide.type === 'video'" class="media">
            <video
              class="uk-width-1-1"
              controls
              :poster="_vaData.apiBase + slide.poster"
              :src="_vaData.apiBase + slide.src"
            ></video>
          </div>

          <div v-if="slide.type === 'image'" class="media">
            <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
          </div>

          <div v-if="slide.type === 'iframe'" class="media">
            <div
              class="vx-ratio-box mobile_fullscreen vx-ratio-box-16-9 uk-background-muted"
            >
              <iframe :src="slide.src" frameborder="0"></iframe>
            </div>
          </div>

          <div v-if="slide.caption" class="caption">
            <span>{{ slide.caption }}</span>
          </div>
        </template>
      </div>
      <div v-if="slide.body" class="uk-padding body">
        <vue-markdown>{{ slide.body }}</vue-markdown>
      </div>
    </vue-custom-scrollbar>
  </div>
</template>

<script>
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";

export default {
  name: "Slide",
  props: {
    slide: Object
  },
  components: {
    vueCustomScrollbar
  },
  mounted() {
    const isSmall = window.innerWidth < 960;
    this.scrollbar.settings.swicher = !isSmall;
  },
  data() {
    return {
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: true,
          swicher: true
        }
      }
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped="">
.mobile_fullscreen {
  padding-top: 0 !important;
  height: 99.8vh;
}
</style>
