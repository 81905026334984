<template>
  <div class="contactsMenu">
    <div class="contactsMenu__inner">
      <a
        class="button"
        v-if="strings.website_url"
        :href="strings.website_url"
        target="_blank"
      >
        <span class="button__text">{{
          strings.website_text || "Website"
        }}</span>
        <i class="button__icon button__icon--website"></i>
      </a>
      <a
        class="button"
        v-if="strings.phone"
        :href="strings.phone"
        target="_self"
      >
        <span class="button__text">{{ strings.phone || "Nummer" }}</span>
        <i class="button__icon button__icon--phone"></i>
      </a>

      <a
        class="button"
        v-if="strings.mail_box"
        @click="showContactForm"
        :title="form.title"
      >
        <span class="button__text">{{ strings.mail_box || "Mail" }}</span>
        <i class="button__icon button__icon--mail"></i>
      </a>

      <a
        class="button"
        v-if="strings.booking"
        :href="strings.booking"
        target="_blank"
      >
        <span class="button__text">{{ strings.booking_text || "Termin" }}</span>
        <i class="button__icon button__icon--calendar"></i>
      </a>
    </div>
  </div>
</template>
<script>
import ContactForm from "./ContactForm.vue";
import { bus } from "../main";
export default {
  name: "ContactMenu",
  data: function() {
    return {
      strings: this._vaData.strings,
      form: this._vaData.strings.forms.find(
        f => f.form_id === "default_contact_form"
      )
    };
  },
  props: {},
  methods: {
    showContactForm() {
      this.form.referer = null;

      this.$modal.show(
        ContactForm,
        {
          form: this.form
        },
        {
          height: "auto",
          adaptive: true,
          scrollable: true
        }
      );
    }
  },
  created() {
    bus.$on("show_contact_form", () => {
      this.showContactForm();
    });

    document.querySelector(".videoapp").addEventListener("click", event => {
      const path = event.path || (event.composedPath && event.composedPath());
      // click wherever to close open link
      if (path.indexOf(document.querySelector(".link--open")) === -1) {
        this.openLink = null;
      }
    });
  }
};
</script>
<style lang="scss" scoped>
.contactsMenu {
  position: fixed;
  z-index: 100;
  left: 45px;
  top: 0;
  bottom: 0;
  height: 0;
  width: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: auto;

  @media (max-width: $breakpoint-large) {
    display: none;
  }
}

.contactsMenu__inner {
  display: flex;
  flex-direction: column;
}

.button {
  --height: 44px;
  --textbox-width: 177px;
  margin: 1px 0;
  background-color: $global-emphasis-color;
  color: #fff;
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-columns: var(--height) var(--textbox-width);
  height: var(--height);
  align-items: center;

  transition: all 0.3s;
  transform: translateX(0);
  &:hover {
    transform: translateX(var(--textbox-width));
    margin-right: 0px;
    text-decoration: none;
    color: #fff;
    background-color: darken($global-emphasis-color, 5);
  }
}
.button__text {
  width: calc(100% + 100px);
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  padding: 15px;
  color: $global-secondary-background;
  text-transform: uppercase;
}

.button__icon {
  width: 50px;
  margin-left: 130px;
  height: 100%;
  background-position: center;
  background-size: 45%;
  background-repeat: no-repeat;
  filter: invert(100%);
}

.button__icon--website {
  background-image: url(../assets/world.svg);
}
.button__icon--phone {
  background-image: url(../assets/receiver.svg);
}
.button__icon--mail {
  background-image: url(../assets/mail.svg);
}
.button__icon--calendar {
  background-image: url(../assets/calendar.svg);
}
</style>
