import { render, staticRenderFns } from "./Slide.vue?vue&type=template&id=6cd651cf&scoped=true&"
import script from "./Slide.vue?vue&type=script&lang=js&"
export * from "./Slide.vue?vue&type=script&lang=js&"
import style0 from "./Slide.vue?vue&type=style&index=0&id=6cd651cf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cd651cf",
  null
  
)

export default component.exports