<template>
  <div class="app-info">
    <span v-if="showTitle">Version</span> {{ version }}
    <span class="uk-text-right powered_by"
      ><a
        href="https://visionsbox.de/projekte/digitale-messe-ideen"
        target="_blank"
        >powered by VISIONSBOX</a
      ></span
    >
  </div>
</template>
<script>
export default {
  name: "AppInfo",
  data() {
    return {
      version: process.env.VUE_APP_VERSION
    };
  },
  props: {
    showTitle: Boolean
  },
  created() {},
  components: {}
};
</script>
<style lang="scss" scoped>
.app-info {
  font-size: 0.7em;
  text-align: left;
  opacity: 0.5;
}
.powered_by {
  float: right;
  a {
    color: #000;
  }
  a:hover {
    color: $global-emphasis-color;
  }
}
</style>
