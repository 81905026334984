<template>
  <div class="">
    <button class="vm-modal__close" @click="$emit('close')">
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>

    <div
      class="uk-background-default uk-box-shadow-medium m-va_modal__container"
    >
      <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
    </div>
  </div>
</template>

<script>
import Slide from "./SlideMedia.vue";

export default {
  name: "ModalMedia",
  data: function() {
    return {
      slickSettings: {
        dots: false,
        arrows: false
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    slides: Array
  },
  components: {
    Slide
  },
  methods: {},
  created() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.m-va_modal__container {
  overflow: hidden;
}

.vm--modal > div {
  max-width: 100% !important;
  height: 100% !important;
  padding: 0;
  margin: 0;
}
.vm--modal {
  top: 0% !important;

  &.c-va_modal {
    top: 0% !important;
  }
}
.vm-modal__close {
  top: 75px;
  right: 33px;
}
</style>
