<template>
  <div>
    <button
      class="vm-modal__close"
      @click="$emit('close')"
      :title="strings.close"
    >
      <span uk-icon="icon: close; ratio: 1.5"></span>
    </button>

    <div class="c-va_modal__header"></div>
    <div class="c-va_modal__container">
      <div class="aside">
        <div class="aside__header">
          <h2 v-if="title" v-html="title"></h2>
          <h3 v-if="subtitle" v-html="subtitle"></h3>
        </div>

        <vue-custom-scrollbar
          :settings="scrollbar.settings"
          class="aside__body"
        >
          <div v-if="body">
            <vue-markdown>
              {{ body }}
            </vue-markdown>
          </div>
        </vue-custom-scrollbar>

        <div class="modal_contact_padding">
          <div
            class="uk-grid uk-grid-small uk-child-width-expand uk-margin-remove-top modal_contact"
            uk-grid
          >
            <div
              v-if="strings.website_url"
              class="finder_link__button uk-button"
            >
              <a
                class="finder_link uk-button finder_link--world"
                :href="strings.website_url"
                target="_blank"
              ></a>
            </div>
            <CallToContact :referer="title"></CallToContact>
            <div v-if="strings.phone" class="finder_link__button uk-button">
              <div class="uk-nav-dropdown">
                <button class="finder_link uk-button">
                  <span uk-icon="icon: receiver" class=""></span>
                </button>
                <vue-custom-scrollbar
                  class="dropdown padding-small"
                  uk-dropdown="pos:top-right; mode:click;'
                  "
                  :settings="scrollbar.settings"
                >
                  <span class="modal_number" :href="strings.phone">{{
                    strings.phone
                  }}</span>
                </vue-custom-scrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main">
        <VueSlickCarousel
          v-bind="slickSettings"
          @beforeChange="beforeChangeCarousel"
          @afterChange="afterChangeCarousel"
          @init="onInitCarousel"
          ref="carousel"
        >
          <Slide v-for="slide in slides" :key="slide.id" :slide="slide"></Slide>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import Slide from "./Slide.vue";
import CallToContact from "./CallToContact.vue";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

// optional style for arrows & dots
export default {
  name: "ModalDefault_desktop",
  data: function() {
    return {
      strings: this._vaData.strings,
      slickSettings: {
        dots: true,
        arrows: true
      },
      slideIndex: 0,
      scrollbar: {
        settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false
        }
      }
    };
  },
  props: {
    title: String,
    subtitle: String,
    header_title: String,
    header_subtitle: String,
    category: Object,
    body: String,
    slides: Array,
    link_list: Array
  },
  components: {
    VueSlickCarousel,
    Slide,
    vueCustomScrollbar,
    CallToContact
  },
  created() {},
  mounted() {
    // open all links in modal in new tab
    document.querySelectorAll(".c-va_modal__container a").forEach($link => {
      $link.addEventListener("click", e => {
        e.preventDefault();
        window.open(e.target.href);
        return;
      });
    });
    document
      .querySelectorAll(".c-va_modal__container video")
      .forEach($video => {
        // hide controls of all videos
        $video.controls = "";
      });
    if (document.querySelector(".slick-current video")) {
      document.querySelector(".slick-current video").controls = "controls";
    }
  },
  methods: {
    onInitCarousel() {},
    afterChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      if ($video) {
        // show controls
        $video.controls = "controls";
      }
    },
    beforeChangeCarousel() {
      const $currentSlide = this.$refs.carousel.$el.querySelector(
        ".slick-current"
      );
      const $video = $currentSlide.querySelector("video");
      const $iframe = $currentSlide.querySelector("iframe");

      if ($video) {
        $video.pause();
        // hide controls
        $video.controls = "";
      }
      if ($iframe) {
        const src = $iframe.getAttribute("src");
        if (src.includes("youtube")) {
          $iframe.contentWindow.postMessage(
            '{"event":"command","func":"stopVideo","args":""}',
            "*"
          );
        }
      }
    }
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.c-va_modal {
  & > div,
  .c-va_modal__container {
    height: 100%;
    overflow: hidden;
  }

  .aside {
    @extend .uk-width-1-3,
      .uk-margin-small-right,
      .uk-box-shadow-medium,
      .uk-flex,
      .uk-flex-column;
    background: #fff;

    height: 100%;
  }

  .aside__header {
    @extend .uk-padding;
    color: $global-tertiary-color;
    font-weight: 400;
    position: relative;
    z-index: 1;
    text-transform: uppercase;

    h2 {
      margin: 0;

      color: $global-tertiary-color;
      text-transform: uppercase;
    }

    h3 {
      margin: 0;
      color: $global-tertiary-color;
    }
  }

  .aside__body {
    @extend .uk-padding, .uk-flex-1;
    height: 100%;
    overflow-y: scroll;
    font-family: "vogels", arial, sans-serif;
    font-size: 14px;
    line-height: 1.5;
    h3 {
      color: $global-tertiary-color;
    }
  }

  .aside__footer {
    position: relative;
    z-index: 1;
    @extend .uk-padding;
    padding-bottom: 15px;
    padding-top: 15px;
    border-top: 1px solid $global-muted-color;
    background-color: #efefef;
  }

  .main {
    @extend .uk-width-expand, .uk-background-default, .uk-box-shadow-medium;

    .slick-list,
    .slick-track {
      height: 100%;
    }

    .slick-slide {
      & > div,
      .slide {
        background: #fff;
        height: 100%;
      }
    }

    .slide {
    }
  }
}
.finder_link__button {
  @extend .uk-background-default;
  @extend .uk-padding;
  a {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 30px 0;
  }
  button {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0.9em;
    line-height: 1.4;
    width: 100%;
    margin: 30px 0;
  }
  @media (max-width: 1200px) {
    a {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
  }
  @media (max-width: 1100px) {
    a {
      font-size: 10px;
    }
    button {
      font-size: 10px;
    }
  }
}
.finder_link {
  @extend .uk-button, .uk-button-primary, .uk-button-large;
}
.modal_contact_padding {
  padding: 20px 30px;
}
.modal_number {
  color: $global-muted-color;
  font-size: 14px !important;
  pointer-events: none;
  padding: 0 !important;
}
.padding-small {
  padding: 15px !important;
}
.margin-small-right {
  margin-right: 10px !important;

  @media (max-width: 1200px) {
    margin-right: 5px !important;
  }
  @media (max-width: 1100px) {
    margin-right: 0px !important;
  }
}

.vm-modal__close {
  width: 35px !important;
  height: 35px !important;
  padding: 0.2em !important;
  right: 50px !important;

  @media (max-width: 800px) {
    width: 25px !important;
    height: 25px !important;
    padding: 0.2em !important;
  }
  @media (max-height: 800px) {
    width: 30px !important;
    height: 30px !important;
    padding: 0.3em !important;
  }
}
.finder_link--world {
  background-image: url("../../assets/world.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20%;
  height: 43%;
  @media (max-width: 1200px) {
    background-size: 35%;
  }
}

.uk-grid-small {
  margin-left: 0px !important;
  padding-left: 0px;
}

.uk-grid-small > * {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.callToContact {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.uk-button {
}
.modal_contact {
  height: 80px;
}
</style>
