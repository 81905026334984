<template lang="html">
  <footer class="footer">
    <div class="external">
      <a
        class="footer__button icon-button icon-button--website"
        v-if="strings.website_url"
        :href="strings.website_url"
        target="_blank"
      ></a>
      <a
        class="footer__button icon-button button__icon--phone"
        v-if="strings.phone"
        :href="strings.phone"
        target="_self"
      ></a>
      <a
        class="footer__button icon-button button__icon--mail"
        v-if="strings.mail_box"
        @click="showContactForm"
        :title="form.title"
      >
      </a>
      <a
        class="footer__button icon-button button__icon--calendar"
        v-if="strings.booking"
        :href="strings.booking"
        target="_blank"
      ></a>
    </div>
    <div class="navigation">
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          v-show="!isHome"
          class="footer__button icon-button icon-button--home"
          @click="goHome"
        ></div>
      </transition>
      <Navigation class="footer__button" />
    </div>
  </footer>
</template>

<script>
import { bus } from "../../main";
import Navigation from "../Navigation.vue";

import ContactForm from "../ContactForm.vue";
export default {
  components: {
    Navigation
  },
  data() {
    return {
      showBack: false,
      locations: this._vaData.locations,
      strings: this._vaData.strings,
      isHome: true,
      form: this._vaData.strings.forms.find(
        f => f.form_id === "default_contact_form"
      )
    };
  },
  mounted() {
    bus.$on("location_reached", (id, history) => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.showBack = location.show_back_btn && history.length > 0;
      this.isHome = this._vaData.settings.start_location === id;
    });

    bus.$on("location_left", () => {
      this.showBack = false;
    });
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goHome() {
      bus.$emit("go_to_location", this._vaData.settings.start_location);
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    },
    showContactForm() {
      this.form.referer = null;

      this.$modal.show(
        ContactForm,
        {
          form: this.form
        },
        {
          height: "auto",
          adaptive: true,
          scrollable: true
        }
      );
    }
  },
  created() {
    bus.$on("show_contact_form", () => {
      this.showContactForm();
    });
  }
};
</script>

<style lang="scss" scoped>
.footer {
  background: #e6e5e6;
  flex-shrink: 0;
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  color: #40403f;
  height: 50px;

  & > div {
    height: 100%;
  }

  .footer__button {
    background-color: $global-emphasis-color;
    border-left: 1px solid rgba(#fff, 0.1);
    border-right: 1px solid rgba(#000, 0.1);

    text-align: center;
    box-sizing: border-box;
    transition: all 0.2s;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &:last-child() {
      border-right: 0;
    }
    &:first-child() {
      border-left: 0;
    }

    &:hover {
      background-color: darken($global-emphasis-color, 5);
    }

    ::v-deep .trigger {
      padding: 0 20px;
    }
  }

  .external,
  .navigation {
    display: flex;
    flex-direction: row;
  }

  .icon-button {
    display: block;
    width: 50px;
    height: 100%;
    background-color: $global-emphasis-color;
  }
  .icon-button--website {
    background-size: auto 52%;
    background-image: url(../../assets/world_dark.svg);
  }
  .icon-button--home {
    // background-size: auto 40%;
    // background-image: url(../../assets/haus.svg);
    background-size: auto 64%;
    background-image: url(../../assets/arrow_left.svg);
  }
  .icon-button--back {
    background-size: auto 64%;
    background-image: url(../../assets/arrow_left.svg);
  }
  .button__icon--phone {
    background-image: url(../../assets/receiver_dark.svg);
    background-size: auto 60%;
  }
  .button__icon--calendar {
    background-image: url(../../assets/calendar_dark.svg);
    background-size: auto 60%;
  }
  .button__icon--mail {
    background-image: url(../../assets/mail_dark.svg);
    background-size: auto 60%;
  }
}
</style>
