<template>
  <div class="title">
    <div>
      <h1 class="title__main">{{ strings.app_title }}</h1>
      <h2 class="title__location">{{ locationTitle }}</h2>
    </div>
    <div></div>
  </div>
</template>
<script>
import { bus } from "../main";

export default {
  name: "Title",
  data() {
    return {
      strings: this._vaData.strings,
      locationTitle: null
    };
  },
  props: {},
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    }
  },
  created() {
    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
    });

    bus.$on("location_left", () => {
      this.locationTitle = null;
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 55px;
  display: flex;
  width: 100%;

  align-items: flex-end;
  gap: 15px;
  justify-content: space-between;
}

.title__main {
  line-height: 1;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  font-size: 2em;
  padding-bottom: 10px;
}

.title__location {
  color: $global-tertiary-color;
  margin: 0;
  line-height: 1;
  font-size: 1.6em;
  font-weight: 400;
  text-transform: uppercase;
  margin-right: 30px;
}
</style>
