<template>
  <div class="videothing">
    <!-- here -->
  </div>
</template>

<script>
import VideoThing from "videoThing";
import "../../../node_modules/videoThing/dist/videoThing.css";
import { bus } from "../../main";

export default {
  name: "VideoThing",
  props: {
    settings: Object
  },
  created() {
    bus.$on("go_back", () => {
      this.videoThing.goBack();
    });
    bus.$on("show_hotspots", () => {
      this.videoThing.showHotspots();
    });
    bus.$on("hide_hotspots", () => {
      this.videoThing.hideHotspots();
    });
    bus.$on("show_navigation", () => {
      this.videoThing.showNavigation();
    });
    bus.$on("hide_navigation", () => {
      this.videoThing.hideNavigation();
    });
    bus.$on("show_menus", () => {
      this.videoThing.showMenus();
    });
    bus.$on("hide_menus", () => {
      this.videoThing.hideMenus();
    });
    bus.$on("go_to_location", id => {
      this.videoThing.goToLocation(id);
    });
  },
  methods: {},
  mounted() {
    const elem = document.querySelector(".videothing");
    this.videoThing = new VideoThing(
      elem,
      this._vaData.locations,
      this._vaData.destinations,
      this.settings
    );

    this.videoThing.on("location_reached", id => {
      bus.$emit("location_reached", id, this.videoThing.history);

      this.$nextTick(() => {
        // if last location is new location, remove it from history
        const history = this.videoThing.history;
        if (
          history.length > 1 &&
          history[history.length - 1] === history[history.length - 2]
        ) {
          this.videoThing.history.pop();
        }
      });
    });

    this.videoThing.on("location_left", id => {
      bus.$emit("location_left", id, this.videoThing.history);
    });

    this.videoThing.on("custom_button_clicked", (e, item) => {
      bus.$emit("show_content", item.target_product.id);
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.videothing {
  $hotspot-background: $global-emphasis-color;
  $navigation-background: $global-emphasis-color;

  flex-shrink: 0;
  flex-grow: 1;

  position: relative;
  @media (max-width: $breakpoint-large) {
    height: auto !important;

    // aspect-ratio: 2 / 1;
    // @supports not (aspect-ratio: 2 / 1) {
    &:before {
      float: left;
      padding-top: 50%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  @media (max-width: $breakpoint-medium) {
    // aspect-ratio: 16 / 9;
    // @supports not (aspect-ratio: 16 /9) {
    &:before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    // }
  }

  .controls--viewport_relative {
    // da ist einfehler im videothing css.
    position: fixed !important;
  }

  .controls {
    .button {
      // height: 28px;
      padding: 0.25em 0.6em;
      user-select: none;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 14px;
      white-space: normal;
      min-width: 135px;
      max-width: 200px;
      height: 28px;
      position: relative;
      background-color: $hotspot-background;
      border: 2px solid $hotspot-background;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px, rgba(0, 0, 0, 0.4) 0 2px 4px;
      transition: background-color 0.2s, border-color 0.2s, color 0.2s;
      // box-shadow: rgba(0, 0, 0, 0.2) 0 3px 4px, rgba(0, 0, 0, 0.4) 0 4px 5px;
      @media (max-width: 1600px) {
        padding: 0.25em 0.4em;
      }

      &.button_nowrap {
        white-space: nowrap;
      }

      &:hover {
        background: #fff;
        color: $global-emphasis-color;
        border: 2px solid $global-emphasis-color;
      }

      &.btn_border_right {
        @media (max-width: 1550px) {
          margin-right: 150px !important;
        }
        @media (max-width: 1400px) {
          margin-right: 300px !important;
        }
        @media (max-width: 1200px) {
          margin-right: 50px !important;
        }
      }
      &.btn_border_left {
        @media (max-width: 1550px) {
          margin-left: 150px !important;
        }
        @media (max-width: 1400px) {
          margin-left: 300px !important;
        }
        @media (max-width: 1200px) {
          margin-left: 50px !important;
        }
      }
      &.btn_border_right--small {
        @media (max-width: 1400px) {
          margin-right: 150px !important;
        }
        @media (max-width: 1200px) {
          margin-right: 50px !important;
        }
      }
      &.btn_border_left--small {
        @media (max-width: 1400px) {
          margin-left: 150px !important;
        }
        @media (max-width: 1200px) {
          margin-left: 50px !important;
        }
      }

      &[data-type="hotspot"] {
        @media (max-width: $breakpoint-large) {
          background-image: url(../../assets/more.svg);

          &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: url(../../assets/more--hover.svg);
            opacity: 0;
            transition: all 0.2s;
          }

          &:hover:before {
            opacity: 1;
          }
        }
      }

      &[data-type="navigation"] {
        @media (max-width: $breakpoint-large) {
          background-image: url(../../assets/arrow_single_right.svg);
          &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background-image: url(../../assets/arrow_single_right--hover.svg);
            opacity: 0;
            transition: all 0.2s;
          }

          &:hover:before {
            opacity: 1;
          }
        }
      }

      &[class*="btn-icon"] {
        height: 50px !important;
        width: 50px !important;
        min-width: 40px;
        box-sizing: border-box;
        border-radius: none;
        position: relative;
        background-color: transparent;
        border: none;
        box-shadow: none;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 130%;

        .button__text {
          display: none;
        }
      }

      &.btn-icon--left {
        background-image: url("../../assets/chevron-left.svg");
        &:hover {
          background-image: url("../../assets/chevron-left--hover.svg");
        }
      }
      &.btn-icon--right {
        background-image: url("../../assets/chevron-right.svg");
        &:hover {
          background-image: url("../../assets/chevron-right--hover.svg");
        }
      }

      span {
        max-width: 100%;
        max-height: 100%;
        text-align: center;
        // overflow: hidden;
        // display: -webkit-box;
        // -webkit-line-clamp: 2;
        // -webkit-box-orient: vertical;
      }

      @media (max-width: $breakpoint-large) {
        // display: none
        min-width: auto;
        width: 20px;
        height: 20px;
        display: block;
        padding: 0;
        background-size: contain;
        span {
          display: none;
        }
      }

      &[class*="btn-dot"] {
        min-width: 40px;
        height: 40px;
        width: 40px;
        box-sizing: border-box;
        border-radius: 50%;
        position: relative;
        background: transparent !important;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          transform: scale(1);
          animation: pulse 2s infinite;
          background-color: $global-secondary-background;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 50%;
        }

        .button__text {
          display: none;
        }
        @media (max-width: 960px) {
          &[class*="btn-dot"] {
            display: none;
          }
        }
        &.btn-dot--plus:after {
          background-image: url("../../assets/plus-yellow.svg");
        }
      }
    }
  }
}
</style>
